import clsx from 'clsx';
import s from './ProductCard.module.scss';
import sg from '../../styles/global.module.scss';
import { Product } from '../../models/product';
import CardItem from '../CardItem/CardItem';
import MediaSwiper from '../MediaSwiper/MediaSwiper';
import React, { useMemo, useState } from 'react';
import { useTypedSelector } from '../../redux/hooks';
import BuyBlock from '../BuyBlock/BuyBlock';
import { IS_INSTAGRAM, IS_IOS_NATIVE, IS_SAFARI } from '../../utils/browser';
import { getCategoryAndSubcategoriesLine } from './helpers';
import { useLocation } from 'react-router';
import {
  AnalyticEventsEnum,
  getProductAnalyticData,
  trackEvent,
} from '../../Analytics';

export interface ProductCardProps {
  product: Product;
  disableSwipeOnTouch?: boolean;
  showCategory?: boolean;
}

const ProductCard: React.FC<ProductCardProps> = ({
  product,
  disableSwipeOnTouch,
  showCategory,
}) => {
  const activeSubject = useTypedSelector(s => s.subjects.activeSubject);
  const [activeIndex, setActiveIndex] = useState<number>();

  const subtitle = useMemo(
    () => getCategoryAndSubcategoriesLine(product, showCategory),
    [product, showCategory]
  );

  const moreHeight = IS_SAFARI || IS_INSTAGRAM || IS_IOS_NATIVE;

  const location = useLocation();
  const isBusinessPlatform = location.pathname.startsWith('/business');

  return (
    <CardItem
      flex // <---- flexbox wrapper
      onClick={async () => {
        if (!isBusinessPlatform) {
          const analyticData = getProductAnalyticData(product);
          await trackEvent(AnalyticEventsEnum.CLICK__PRODUCT, {
            ...analyticData,
          });
        }
      }}
      routerLink={
        activeSubject?.contentType === 'business'
          ? `products/edit/${product.id}`
          : `/catalog/${product.id}`
      }
    >
      <div
        className={clsx(s.Image__container)}
        style={{ flex: 1 }} // <---- fill all available space
      >
        <MediaSwiper
          isPreview // <---- absolute position
          activeIndex={activeIndex}
          setActiveIndex={setActiveIndex}
          disableSwipeOnTouch={disableSwipeOnTouch}
          uploadedFiles={product.uploadedFiles}
        />
      </div>

      <div
        className={clsx(sg.Stack_Vertical, sg.Gap_10, sg.Width_100)}
        style={{
          height: moreHeight ? '110px' : '100px', // <---- fixed height
        }}
      >
        <div className={clsx(sg.Stack_Horizontal)}>
          <div className={clsx(sg.Stack_Vertical, sg.Gap_5, s.Text_Container)}>
            <p className={clsx(s.Text_Title)}>{product.title}</p>
            <p className={clsx(s.Text_Subtitle)}>{subtitle}</p>
          </div>
        </div>

        <BuyBlock
          isPreview
          priceFrom={product.priceFrom}
          priceTo={product.priceTo}
          priceCurrency={product.priceCurrency}
          priceType={product.priceType}
          contactInfoContextData={{
            businessId: product.business,
            product,
          }}
        />
      </div>
    </CardItem>
  );
};
export default ProductCard;
