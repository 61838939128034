import s from './BusinessDetail.module.scss';
import sg from '../../styles/global.module.scss';
import clsx from 'clsx';
import { IonSegment, IonSegmentButton } from '@ionic/react';
import Button from '../Buttons/Button/Button';
import { Business } from '../../models/business';
import SearchContent from '../SearchContent/SearchContent';
import { SearchTypeEnum } from '../SearchBlock/interface';
import { isPlatform } from '@ionic/core';
import React, { useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router';
import { TaggedText } from '../TaggedText/TaggedText';
import BusinessCard from '../BusinessCard/BusinessCard';
import { useContactInfoContext } from '../ContactInfoModal/context';
import {
  AnalyticEventsEnum,
  getBusinessAnalyticData,
  trackEvent,
} from '../../Analytics';

export interface BusinessDetailProps {
  business: Business;
}

type TabType = 'services' | 'reviews' | 'info';

const BusinessDetail: React.FC<BusinessDetailProps> = ({ business }) => {
  const history = useHistory();
  const params = new URLSearchParams(window.location.search);
  const [tab, _setTab] = useState((params.get('tab') as TabType) || 'info');
  const setTab = (t: TabType) => {
    params.set('tab', t);
    history.replace(window.location.pathname + '?tab=' + t);
    _setTab(t);
  };

  const [wasProductsTabOpened, setWasProductsTabOpened] = useState(false);
  useEffect(() => {
    if (tab === 'services') setWasProductsTabOpened(true);
  }, [tab]);

  const initialSearchArgs = useMemo(
    () =>
      ({
        type: SearchTypeEnum.BY_KEYWORDS,
        searchTerm: '',
        businessId: business.id,
      } as const),
    [business.id]
  );

  const cityName = useMemo(() => {
    const city = business.city?.name;
    if (city) {
      return `${city}, ${business.city?.country.name}`;
    } else {
      return business.cityCustom || '-';
    }
  }, [business]);

  const categoryName = useMemo(() => {
    const category = business.category?.name;
    if (category) {
      return category;
    } else {
      return business.categoryCustom || '-';
    }
  }, [business]);

  const { setInfo } = useContactInfoContext();
  const openContactModal = () => setInfo({ businessId: business.id });

  const analyticData = useMemo(
    () => getBusinessAnalyticData(business),
    [business]
  );

  return (
    <>
      <div
        className={clsx(sg.Stack_Vertical)}
        style={isPlatform('mobile') ? { width: '100%' } : {}}
      >
        <div
          className={clsx(sg.Stack_Horizontal)}
          style={{
            width: isPlatform('desktop') ? '500px' : '100%',
          }}
        >
          <BusinessCard
            softDisabled
            rating={0}
            reviewsCount={0}
            business={business}
            description={business.category?.name || business.categoryCustom}
          />

          <Button
            text="Contact"
            fill="solid"
            color="secondary"
            className={clsx(s.Button)}
            skipCentralizer={true}
            onClick={async () => {
              await trackEvent(AnalyticEventsEnum.CONTACT);
              await trackEvent(
                AnalyticEventsEnum.CLICK__BUSINESS_CONTACT,
                analyticData
              );
              openContactModal();
            }}
          />
        </div>

        <IonSegment
          key={tab}
          value={tab}
          className={clsx(s.IonSegment)}
          mode="ios"
          style={{
            width: isPlatform('desktop') ? '500px' : '100%',
          }}
          onIonChange={async e => {
            const t = e.detail.value as typeof tab;
            setTab(t);
            await trackEvent(AnalyticEventsEnum.CLICK__BUSINESS_TAB, {
              tab: t,
            });
          }}
        >
          <IonSegmentButton value="info" className={clsx(s.SegmentButton)}>
            <p>Info</p>
          </IonSegmentButton>
          <IonSegmentButton value="services" className={clsx(s.SegmentButton)}>
            <p>Services ({business.statistic.productsCount})</p>
          </IonSegmentButton>
          <IonSegmentButton value="reviews" className={clsx(s.SegmentButton)}>
            <p>Reviews ({business.statistic.reviewsCount})</p>
          </IonSegmentButton>
        </IonSegment>
      </div>

      <div
        className={clsx(s.Content__container)}
        style={{
          display: tab === 'services' ? 'block' : 'none',
        }}
      >
        {wasProductsTabOpened && (
          <SearchContent
            hideSearchString
            initialSearchArgs={initialSearchArgs}
          />
        )}
      </div>

      <div
        className={clsx(s.Content__container)}
        style={{
          display: tab === 'reviews' ? 'unset' : 'none',
          width: isPlatform('desktop') ? '500px' : '100%',
        }}
      >
        <p style={{ marginTop: '30px' }}>
          Reviews are not supported yet, but we are working on this :)
        </p>
      </div>

      <div
        className={clsx(s.Content__container)}
        style={{
          display: tab === 'info' ? 'unset' : 'none',
          width: isPlatform('desktop') ? '500px' : '100%',
        }}
      >
        <div
          className={clsx(sg.Stack_Vertical, sg.Gap_20)}
          style={{ marginBottom: '50px', marginTop: '20px' }}
        >
          <TaggedText title="About" value={business.details} />
          <TaggedText title="Location" value={cityName} />
        </div>
      </div>
    </>
  );
};

export default React.memo(BusinessDetail);
