import clsx from 'clsx';
import s from './Header.module.scss';
import sg from '../../styles/global.module.scss';
import {
  IonBackButton,
  IonButton,
  IonButtons,
  IonHeader,
  IonIcon,
  IonTitle,
  IonToolbar,
  isPlatform,
} from '@ionic/react';
import { addOutline, mailOutline } from 'ionicons/icons';
import ButtonMicroWrapper from '../Buttons/ButtonMicroWrapper/ButtonMicroWrapper';
import { logoWhatsapp } from 'ionicons/icons';
import { useLocation } from 'react-router';
import { PlacesAutocomplete } from '../Location/PlacesAutocomplete';
import { useState } from 'react';
import { LocationUnavailableModal } from '../LocationUnavailableModal/LocationUnavailableModal';
import { AnalyticEventsEnum, trackEvent } from '../../Analytics';
import { useTypedSelector } from '../../redux/hooks';

export interface HeaderProps {
  title?: string;
  backButtonText?: string;
  hideBackButton?: boolean;
  showLocationPicker?: boolean;
  routerLink__message?: string;
  routerLink__add?: string;
}

const Header: React.FC<HeaderProps> = props => {
  const { pathname } = useLocation();
  const isHelpButtonVisible =
    pathname.startsWith('/catalog') &&
    pathname !== '/catalog/assistance/request';

  const [isOpen, setIsOpen] = useState(false);

  const { selectedLocation, detectedLocation } = useTypedSelector(
    s => s.location
  );
  const location = selectedLocation || detectedLocation;

  return (
    <IonHeader>
      <IonToolbar mode="ios">
        {!props.hideBackButton && (
          <IonButtons slot="start">
            <IonBackButton
              text={props.backButtonText}
              defaultHref="/default"
              className={clsx(
                s.IonBackButton,
                sg.Hover_Animation_Transparency,
                'disableIosSafariSwipeBlocker'
              )}
            />
          </IonButtons>
        )}

        {props.showLocationPicker && (
          <div style={{ maxWidth: '220px' }}>
            <PlacesAutocomplete
              openLocationVoter={() => {
                setIsOpen(true);
                trackEvent(AnalyticEventsEnum.LOCATION__LOCK_CLICKED, {
                  location: location?.description,
                });
              }}
              height="35px"
              mode="header"
            />

            <LocationUnavailableModal
              isOpen={isOpen}
              close={() => setIsOpen(false)}
            />
          </div>
        )}

        <IonTitle>
          <h1 className={clsx(s.IonTitle__h1)}>{props.title}</h1>
        </IonTitle>

        <IonButtons slot="end">
          {props.routerLink__message && (
            <ButtonMicroWrapper routerLink={props.routerLink__message}>
              <IonIcon
                icon={mailOutline}
                className={clsx(s.IonIcon__message)}
              />
            </ButtonMicroWrapper>
          )}

          {props.routerLink__add && (
            <ButtonMicroWrapper routerLink={props.routerLink__add}>
              <IonIcon icon={addOutline} className={clsx(s.IonIcon__add)} />
            </ButtonMicroWrapper>
          )}

          {isHelpButtonVisible && (
            <IonButton
              mode="md"
              fill="solid"
              color="success"
              shape="round"
              routerLink={'/catalog/assistance/request'}
              className={clsx(
                s.IonButton__help,
                isPlatform('desktop') ? s.IonButton__help__desktop : undefined,
                'disableIosSafariSwipeBlocker'
              )}
            >
              <IonIcon
                icon={logoWhatsapp}
                style={{
                  fontSize: '24px',
                  marginRight: '5px',
                }}
              />
              <p>Fast Help</p>
            </IonButton>
          )}
        </IonButtons>
      </IonToolbar>
    </IonHeader>
  );
};

export default Header;
