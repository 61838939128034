import React, { useEffect } from 'react';
import { useRouteMatch } from 'react-router';
import Page from '../../../components/Page/Page';
import ProductDetail from '../../../components/ProductDetail/ProductDetail';
import { useGetBusinessQuery, useGetProductQuery } from '../../../redux/api';
import { useCachedFromList, useTypedDispatch } from '../../../redux/hooks';
import Spinner from '../../../components/Spinner/Spinner';
import { choicesSlice } from '../../../redux/slices/choices/slice';

export const UserProductPage: React.FC = () => {
  const match = useRouteMatch<{ productId: string }>();
  const productId = match.params.productId;

  const cachedProduct = useCachedFromList<'Product'>({
    tagName: 'Product',
    objectId: productId,
  });

  const {
    data: fetchedProduct,
    isLoading,
    isFetching,
  } = useGetProductQuery({ id: productId }, { skip: !!cachedProduct });

  const product = cachedProduct || fetchedProduct;

  const { data: business } = useGetBusinessQuery(
    { id: product?.business! },
    { skip: !product }
  );

  const dispatch = useTypedDispatch();
  useEffect(() => {
    if (business?.city?.id)
      dispatch(
        choicesSlice.actions.setSelectedCityIfNotExists(business.city.id)
      );
  }, [business?.city?.id, dispatch]);

  return (
    <Page
      headerProps={{}}
      pageLimitSize="600px"
      buyBlockProps={
        product
          ? {
              isPreview: false,
              priceTo: product.priceTo,
              priceFrom: product.priceFrom,
              priceCurrency: product.priceCurrency,
              priceType: product.priceType,
              contactInfoContextData: {
                businessId: product.business,
                product,
              },
            }
          : undefined
      }
    >
      {isFetching || isLoading || !product ? (
        <Spinner hasCountdown />
      ) : (
        <ProductDetail
          key={product.id + match.path}
          product={product}
          business={business}
        />
      )}
    </Page>
  );
};

export default UserProductPage;
