import { useCallback } from 'react';
import {
  FieldErrors,
  FieldValues,
  Path,
  PathValue,
  UseFormHandleSubmit,
  UseFormReset,
  UseFormSetValue,
  UseFormTrigger,
} from 'react-hook-form';
import { UploadedFile } from '../../@shared/file';
import { useTypedDispatch } from '../../redux/hooks';
import { globalActionCreators } from '../../redux/slices/global/actionCreator';
import { ToastPriorityEnum } from '../../utils/enums';
import { CheckedCategoriesType } from '../ProductCategories/dispatcher';
import type { Components } from '@ionic/core/components';
import { AnalyticEventsEnum, trackEvent } from '../../Analytics';

function filterErrorFieldsForAnalytic(
  errors: FieldErrors<Partial<Record<string, any>>>
) {
  let newObj: Record<string, { message: string; type: string }> = {};
  for (let key in errors) {
    if (errors.hasOwnProperty(key)) {
      newObj[key] = {
        message: String(errors[key]?.message),
        type: String(errors[key]?.type),
      };
    }
  }
  return newObj;
}

export function useSumbitFormCallback<FT extends FieldValues>({
  submitCallback,
  handleSubmit,
  reset,
  trigger,
  setValue,
  isSubmitted,
  customErrorText,
  resetToInitial,
  hideToast,
  trackSubmitError,
  formId,
}: {
  submitCallback: (values: FT) => Promise<void>;
  handleSubmit: UseFormHandleSubmit<Partial<FT>>;
  reset: UseFormReset<Partial<FT>>;
  trigger: UseFormTrigger<Partial<FT>>;
  setValue: UseFormSetValue<Partial<FT>>;
  isSubmitted: boolean;
  customErrorText?: string;
  resetToInitial?: boolean;
  hideToast?: boolean;
  trackSubmitError?: boolean;
  formId?: string;
}) {
  const dispatch = useTypedDispatch();

  const onSubmit = useCallback(
    (event: React.FormEvent<HTMLFormElement>) => {
      handleSubmit(
        // @ts-ignore
        async (values: FT) => {
          await submitCallback(values);
          if (resetToInitial) {
            reset();
          } else {
            reset(values);
          }
        },
        async (errors, event) => {
          if (!hideToast) {
            dispatch(
              globalActionCreators.setToastObject({
                message: customErrorText || 'Please check provided data',
                color: 'danger',
                priority: ToastPriorityEnum.HIGH,
              })
            );
          }

          if (trackSubmitError) {
            await trackEvent(AnalyticEventsEnum.INVALID_SUBMIT, {
              formId,
              formErrors: filterErrorFieldsForAnalytic(errors),
            });
          }

          const firstInputRef = Object.values(errors)[0]?.ref as
            | Components.IonInput
            | Components.IonTextarea
            | undefined;

          if (firstInputRef) {
            const nativeInput = await firstInputRef.getInputElement();
            nativeInput.setAttribute('readonly', 'readonly');
            setTimeout(() => {
              nativeInput.removeAttribute('readonly');
            }, 100);

            nativeInput.scrollIntoView({ behavior: 'smooth', block: 'center' });
            // This does not works
            // firstInputRef.setFocus();
            // nativeInput.focus();
            // nativeInput.blur();
          }
        }
      )(event).catch(error => {
        dispatch(
          globalActionCreators.setToastObject({
            message: error?.message || 'Error occurred. Data not saved.',
            color: 'danger',
            priority: ToastPriorityEnum.HIGH,
          })
        );
      });
    },
    [
      submitCallback,
      handleSubmit,
      reset,
      dispatch,
      customErrorText,
      resetToInitial,
      hideToast,
      trackSubmitError,
    ]
  );

  // ===== Specific per form =====
  const updateUploadedFilesInForm = useCallback(
    (uploadedFiles: (UploadedFile | null)[]) => {
      const fieldName = 'uploadedFiles' as Path<Partial<FT>>;
      const fieldValue = uploadedFiles as PathValue<
        Partial<FT>,
        Path<Partial<FT>>
      >;

      setTimeout(() => {
        setValue(fieldName, fieldValue, {
          shouldDirty: true,
        });

        if (isSubmitted) {
          trigger(fieldName);
        }
      }, 0);
    },
    [isSubmitted, setValue, trigger]
  );

  const updateProductCategoriesInForm = useCallback(
    (
      productCategories: CheckedCategoriesType,
      options?: { shouldTouch?: boolean }
    ) => {
      const fieldName = 'productCategories' as Path<Partial<FT>>;
      const fieldValue = productCategories as PathValue<
        Partial<FT>,
        Path<Partial<FT>>
      >;

      setValue(fieldName, fieldValue, {
        shouldDirty: true,
        shouldTouch: options?.shouldTouch,
      });

      if (isSubmitted) {
        trigger(fieldName);
      }
    },
    [isSubmitted, setValue, trigger]
  );

  return { onSubmit, updateUploadedFilesInForm, updateProductCategoriesInForm };
}
